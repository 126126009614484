<template>
  <Listbox
    as="div"
    class="min-w-52"
    :name="inputName"
    :id="inputName"
    v-model="selected"
    :disabled="disabled"
    :multiple="multiple"
  >
    <ListboxLabel
      class="block text-sm mb-2 text-black"
      v-if="label"
      :for="inputName"
    >
      {{ label }}
    </ListboxLabel>

    <div class="relative">
      <ListboxButton
        :class="[
          'relative w-full cursor-default rounded-md pl-3 pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6',
          isGreyBg ? 'bg-[#E8E8E8] py-2.5' : 'bg-white py-1.5 shadow-sm',
        ]"
      >
        <span v-if="selectedOption && selectedOption.label && !multiple">
          {{ selectedOption.label }}
        </span>
        <span v-else-if="selectedOption && multiple" class="block truncate">{{
          selectedOption.map((option) => option.label).join(', ')
        }}</span>
        <span v-else>{{ t('ui.common.select') }}</span>

        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <FontAwesomeIcon
            :icon="faCaretDown"
            class="size-5 text-gray-400"
            aria-hidden="true"
            color="#74b3e0"
          />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            class="bg-comeen-grey-white"
            v-for="option in options"
            :key="option.key ?? option.label"
            :disabled="option.disabled"
            :value="option.value"
            v-slot="{ active, selected }"
          >
            <li
              class="relative cursor-default select-none py-2 pl-3 pr-9"
              :class="{
                'bg-comeen-primary-400 text-white': active || selected,
                'text-gray-900': !active && !selected,
              }"
            >
              <slot
                name="option"
                :option="option"
                :active="active"
                :selected="selected"
              >
                {{ option.label }}
              </slot>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script
  setup
  lang="ts"
  generic="T extends string | number | boolean | object | null | undefined"
>
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const { t } = useI18n()

// The option.value can be any of string | number | boolean | object | null | undefined (see the generic attribute of script tag above)
// The component need that options prop should respect the following interface :
export type Option = {
  key?: string
  disabled?: boolean
  selected?: boolean
  label: string
  value: T
}

const props = withDefaults(
  defineProps<{
    label?: string
    name?: string
    options: Array<Option>
    disabled?: boolean
    isGreyBg?: boolean
    multiple?: boolean
  }>(),
  {
    isGreyBg: true,
  }
)

const emit = defineEmits<{
  change: [value: T | undefined]
  'update:modelValue': [value: T | undefined]
}>()

const inputName = props.name ?? useId()

const selected = defineModel<T>()

const selectedOption = computed(() => {
  if (
    props.multiple &&
    Array.isArray(selected.value) &&
    selected.value.length > 0
  ) {
    let multipleSelectedOptions = []
    toValue(selected)?.forEach((value) => {
      const option = props.options.find((option) => option.value === value)
      if (option) {
        multipleSelectedOptions.push(option)
      }
    })
    return multipleSelectedOptions
  } else {
    return props.options.find((option) => option.value === toValue(selected))
  }
})

watch(selected, (value) => {
  emit('change', value)
  emit('update:modelValue', value)
})
</script>
